import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateNetworkProjectGeneralInformation from './CreateNetworkProjectGeneralInformation__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  redux: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateNetworkProjectGeneralInformationContainer = connect(
  null,
  mapDispatchToProps,
)(CreateNetworkProjectGeneralInformation);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateNetworkProjectGeneralInformationContainer
      projectId={props.projectId}
      category={props.category}
      subcategory={props.subcategory}
      title={props.title}
      description={props.description}
      isAgent={props.isAgent}
      businessName={props.businessName}
      businessContactName={props.businessContactName}
      headquartersAddress={props.headquartersAddress}
      businessEmail={props.businessEmail}
      businessPhone={props.businessPhone}
      documents={props.documents}
      contacts={props.contacts}
      agency={props.agency}
      agencyOptions={props.agencyOptions}
      canAgencyBeManaged={props.canAgencyBeManaged}
      status={props.status}
      networkSdWanConfigurations={props.networkSdWanConfigurations}
      networkMplsConfigurations={props.networkMplsConfigurations}
      networkPrivateLineConfigurations={props.networkPrivateLineConfigurations}
      railsContext={context}
    />
  </ReduxProvider>
);
